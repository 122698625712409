<template>
 <div class="photoDetails">
  <div class="head">
   <div class="headBar">
    <div class="leftIcon" @click="$router.go(-1)">
     <svg-icon class="navArrow" iconClass="navArrow" />
    </div>
    <span class="worksTitle ellipsis">{{ data.title }}</span>
    <!-- 收藏 -->
    <div class="btnGroup">
     <div
       class="collectionBtn"
       v-if="data && !data.vidStatus.hasCollected"
       @click="collectionWorks(data)"
     >
      <svg-icon class="collect" iconClass="isnovelCollect" />
     </div>
     <div
       class="collectionBtn"
       v-else-if="data"
       @click="collectionWorks(data)"
     >
      <svg-icon class="collect" iconClass="hasnovelCollect" />
     </div>
    </div>
   </div>
  </div>
  <div class="main">
   <div class="photoBox">
    <ImgDecypt class="cover" v-for="(item, index) in data.seriesCover" :src="item" @click.native.stop="openPreview(data.seriesCover, index)" :key="item + index" />
   </div>
  </div>
 </div>
</template>
<script>
import {doCollect, douUcollect} from "@/api/video";
import {collectPhoto, collectTag} from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt/index.vue";

export default {
 name: "PhotoDetails",
 components: {
  ImgDecypt
 },
 props: {
  searValue: {
   type: String
  }
 },
 data() {
  return {
  }
 },
 computed: {
  data() {
   return this.$store.getters.getPhotoData;
  }
 },
 created() {
  console.log(this.data);
 },
 watch: {
  searValue: {
   handler(newName, oldName) {
    this.value = newName;
   },
   immediate: true
  }
 },
 mounted() {
  if (this.data.id) {
   this.getCollect();
  }
 },
 methods: {
  openPreview(list, index) {
   this.$router.push({
    name: "PicturePreview",
    params: {
     imgs: list,
     index: index,
     info: this.data,
     uid: this.data.publisher.uid
    },
   });
  },
  async collectionWorks(item) {
   let req = {
    isCollect: !item.vidStatus.hasCollected,
    objID: item.id,
    type: "img"
   }
   let res = await this.$Api(collectTag, req);

   if(res && res.code == 200){
    if(!item.vidStatus.hasCollected){
     item.vidStatus.hasCollected = true;
     this.$toast({
      message: '收藏成功',
      position: 'top',
     });
    }else{
     item.vidStatus.hasCollected = false;
     this.$toast({
      message: '取消收藏',
      position: 'top',
     });
    }
   }
  },
  async getCollect() {
   let req = {
    id: this.data.id,
   }
   let res = await this.$Api(collectPhoto, req);

   if(res && res.code == 200){
    this.data.vidStatus.hasCollected = res.data;
   }
  },
 }
}
</script>
<style lang="scss" scoped>
.photoDetails {
 width: 100%;
 height: 100%;
 .head {
  //position: relative;
  height: 44px;
  background: #fff;
  width: 100%;

  .headBar {
   display: flex;
   justify-content: space-between;
   height: 44px;
   align-items: center;
   z-index: 999;
   margin-top: 2px;
   .leftIcon {
    padding: 0 16px;

    .navArrow {
     width: 20px;
     height: 20px;
    }
   }
   .worksTitle {
    font-size: 18px;
    color: rgb(0, 0, 0);
    font-weight: bold;
   }
  }

  .btnGroup {

   .collectionBtn {
    margin-right: 12px;

    .collect {
     width: 24px;
     height: 24px;
    }

    img {
     width: 60px;
     height: 24px;
    }
   }
  }
 }
 .main {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 0 11px;
  .photoBox {
   padding-top: 20px;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-gap: 6px;
   .cover {
    width: 113px;
    height: 114px;
   }
  }
 }
}
</style>
